jQuery('.banner-slides').slick({
	dots: true,
	arrows: false,
	fade: true,
	infinite: true,
	speed: 300,
	slidesToShow: 1,
	adaptiveHeight: true
});

jQuery('.about-carousel').slick({
	infinite: true,
	dots: true,
	arrows: false,
	autoplay: false,
	autoplaySpeed: 3000,
	adaptiveHeight: true,
	slidesToShow: 1

});

jQuery('.testimonial-slider').slick({
	infinite: true,
	dots: true,
	arrows: false,
	autoplay: false,
	autoplaySpeed: 3000,
	adaptiveHeight: true,
	slidesToShow: 1

});

jQuery('.offering-slides').slick({
	infinite: true,
	fade: true,
	dots: true,
	arrows: false,
	autoplay: false,
	autoplaySpeed: 3000,
	adaptiveHeight: true,
	slidesToShow: 1

});

